<!--
 * @Description: 新增、修改门店
 * @Author: 琢磨先生
 * @Date: 2022-05-20 17:37:48
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-11 15:14:26
-->


<template>
  <el-dialog
    v-model="dialogVisible"
    width="800px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :title="title"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="图片">
        <upload-avatar :url="form.pic_url" @change="avatarChange"></upload-avatar>
      </el-form-item>

      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="店长" prop="master_id">
        <el-tag v-if="form.master" type="info">{{form.master.chs_name}}、{{form.master.mobile_phone}}</el-tag>
        <emp-choose :single="true" text="选择" @success="masterChangeSuccess"></emp-choose>
        <!-- <el-select
          v-model="form.master_id"
          remote
          clearable
          filterable
          reserve-keyword
          placeholder="请输入姓名、手机号搜索"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in empOptions"
            :key="item.id"
            :label="item.chs_name"
            :value="item.id"
          >
            {{ item.chs_name }}{{ item.mobile_phone }}
          </el-option>
        </el-select>
        <div class="help-block">
          <el-icon><WarningFilled /></el-icon>搜索后需选择相应人员
        </div>-->
      </el-form-item>
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>
      <el-form-item label="描述">
        <el-col :span="20" :offset="0">
          <el-input v-model="form.description" type="textarea" placeholder></el-input>
        </el-col>
      </el-form-item>
      <!-- <el-form-item>
        <div class="row">
          <div class="col" v-for="(item, index) in position_list" :key="item">
            <div class="box">
              <b style="margin-bottom:20px;">{{ index + 1 }}、{{ item.post.name }}</b>
              <post-emp :model="item" :managers="form.manager_list" @change="empChange"></post-emp>
            </div>
            <div class="space" v-if="index < position_list.length - 1">
              <div class="arrow"></div>
            </div>
          </div>
        </div>
      </el-form-item>-->
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :disabled="loading" :loading="loading">确定</el-button>
      <el-button @click="cancelClick" :disabled="loading">取消</el-button>
    </template>
  </el-dialog>
</template>

<script>
import UploadAvatar from "@/views/upload/upload_avatar";
import EmpChoose from "@/views/components/emp_choose.vue";

export default {
  components: {
    UploadAvatar,
    // PostEmp,
    EmpChoose,
  },
  data() {
    return {
      loading: false,
      title: "",
      dialogVisible: false,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        master_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      //搜索到的人员
      empOptions: [],
      //门店管理员等级数据
      position_list: [],
    };
  },
  props: ["item", "reload"],
  watch: {
    item: {
      handler() {
        this.form = {};
        if (this.item) {
          this.title = "新增门店";
          this.dialogVisible = true;
          if (this.item.id) {
            this.title = "修改门店";
            this.form = Object.assign({}, this.item);
            if (this.item.master) {
              this.empOptions = [this.item.master];
            }
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    // this.loadData();
  },
  methods: {
    /**
     * 加载门店管理等级数据
     */
    loadData() {
      this.$http.get("seller/v1/shop/position").then((res) => {
        if (res.code == 0) {
          this.position_list = res.data;
        }
      });
    },
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;

          var manager_list = [];
          this.position_list.forEach((item) => {
            if (item.emp_list) {
              manager_list.push({
                post_id: item.post_id,
                emp_list: item.emp_list,
              });
            }
          });
          this.form.manager_list = manager_list;

          this.$http
            .post("seller/v1/shop/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.dialogVisible = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.dialogVisible = false;
      // if (this.reload) {
      //   this.reload();
      // }
    },

    // /**
    //  * 搜索负责人
    //  */
    // remoteMethod(q) {
    //   this.loading = true;
    //   this.$http.get("seller/v1/emp/select?q=" + q).then((res) => {
    //     if (res.code == 0) {
    //       this.empOptions = res.data;
    //     }
    //     this.loading = false;
    //   });
    // },
    /**
     * 图片上传后更新
     */
    avatarChange(url) { 
      this.form.pic_url = url;
    },
    // /**
    //  * 管理人员选择变更
    //  */
    // empChange(item) {
    //   var model = this.position_list.find((x) => x.post_id == item.post_id);
    //   model.emp_list = item.emp_list;
    //   console.log(this.position_list);
    // },
    masterChangeSuccess(list) {
      if (list.length > 0) {
        this.form.master = list[0];
        this.form.master_id = list[0].id;
      }
    },
  },
};
</script>

<style  scoped>
.row {
  display: flex;
}

.row .col {
  flex-grow: 1;
  position: relative;
  display: flex;
}

.row .col:last-child {
  margin-right: 0px;
}

.row .col .space {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.row .col .arrow {
  position: relative;
  width: 12px;
  height: 12px;
  border: 2px solid var(--el-text-color-placeholder);
  border-left: 0;
  border-top: 0;
  transform: rotate(-45deg);
}

.row .col .box {
  flex: 1;
}
</style>
